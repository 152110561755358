<div data-ng-include="'blocks/conference_logo.html'"></div>

<div class="wrapper-lg">
    <div class="padder-lg">

        <div data-ng-include="'blocks/conference_nav.html'"></div>

        <p class="font-bold">
            Please indicate your availability for each day of the meeting by clicking Yes or No below.
            <span ng-if="!is_virtual">
                <br>When you have finished, click the Save <span ng-show="task.status == '0'">and Continue</span> button.
            </span>
        </p>
        <div class="line  line-double line-lg b-b b-t b-primary2"></div>

        <form name="conference_availability">
            <table class="table borderless">
                <tr>
                    <th class="text-center">Yes</th>
                    <th class="text-center">No</th>
                    <th width="100%"></th>
                </tr>
                <tr ng-repeat="day in availability.days_available track by $index">
                    <td nowrap="">
                        <label class="i-checks i-checks-sm"><input
                                type="radio"
                                name="availability_{{$index}}"
                                ng-init="available_dates[$index] = day.availability"
                                ng-model="available_dates[$index]"
                                value="Yes"
                        ><i></i></label>
                    </td>
                    <td nowrap="">
                        <label class="i-checks i-checks-sm"><input
                                type="radio"
                                name="availability_{{$index}}"
                                ng-init="available_dates[$index] = day.availability"
                                ng-model="available_dates[$index]"
                                value="No"
                        ><i></i></label>

                    </td>
                    <td nowrap=""
                        xxxxng-class="{'text-muted' : (day.availability == 'Yes' || day.availability == 'No')}">
                        {{formatMomentDate(day.date,'MM/DD/YYYY')}} - {{formatMomentDate(day.date,'dddd')}}
                    </td>
                </tr>
            </table>

            <div ng-if="is_virtual">
                <p class="font-bold m-t-lg">
                    Please indicate your preference for recording sessions. <br>When you have finished, click the Save <span ng-show="task.status == '0'">and Continue</span> button.
                </p>

                <div class="line  line-double line-lg b-b b-t b-primary2"></div>

                <div class="m-l-md">
                    <p class="font-bold">
                        Recording Day Preference
                    </p>

                    <div class="checkbox" ng-repeat="day in recording_days track by $index">
                        <label class="i-checks i-checks-sm">
                            <input type="checkbox"
                                   name="recording_day[]"
                                   ng-model="day.selected"
                            > <i></i>
                            {{day.label}}
                        </label>
                    </div>

                    <p class="font-bold">
                        Recording Time Preference
                    </p>

                    <div class="checkbox" ng-repeat="time_slot in recording_time_slots track by $index">
                        <label class="i-checks i-checks-sm">
                            <input type="checkbox"
                                   name="recording_time_slot[]"
                                   ng-model="time_slot.selected"
                            > <i></i>
                            {{time_slot.label}}
                        </label>
                    </div>
                </div>
            </div>

            <div class="line  line-double line-lg b-b b-t b-primary2"></div>

            <div>
                <ul>
                    <li ng-show="note.note_title == 'Availability Comment'"
                        ng-repeat="note in conference.faculty.notes">{{note.note}}<br>
                        <em class="small">Entered on {{formatMomentDate(note.updated_at, 'MM/DD/YYYY')}}</em></li>
                </ul>
                <div>
                    <em>Comments (e.g. special availability requests, reasons you can not attend the entire
                        meeting,etc.)</em>
                    <textarea ng-model="availability_comments" style="width: 100%; height: 50px;"></textarea>
                </div>
            </div>

            <div class="line line-dashed b-b b-primary2"></div>

            <div class="m-t-xl text-danger" ng-show="!replied">
                <span class="font-bold">You must respond to all availability questions before proceeding.</span>
                <div class="line line-dashed b-b b-danger"></div>
            </div>

            <div class="m-t-lg text-center">
                <span ng-if="is_virtual">
                    <button ng-disabled="!replied || !recording_days_replied || !recording_time_slots_replied"
                            ng-click="saveTask()" type="button" class="btn btn-success">Save <span
                            ng-show="task.status == '0'">and Continue</span></button>
                </span>
                <span ng-if="!is_virtual">
                    <button ng-disabled="!replied"
                            ng-click="saveTask()" type="button" class="btn btn-success">Save <span
                            ng-show="task.status == '0'">and Continue</span></button>
                </span>
                <button ng-click="cancelTask()" type="button" class="btn btn-danger m-l-sm ">Cancel</button>
            </div>

    </div>
</div>