<div data-ng-include="'blocks/conference_logo.html'"></div>

<div class="wrapper-lg">
    <div class="padder-lg">

        <div data-ng-include="'blocks/conference_nav.html'"></div>

        <p class="font-bold">
            So we may optimize your experience at TCT, please fill out all of the following sections. Required items are markeded with an asterisk(*). You must click the Add button on the right side of the page after each entry in all sections below.
            Click the Save <span ng-show="task.status == '0'">and Continue</span> button at the bottom of this page when you are finished.
        </p>

        <!-- GENERAL TOPICS -->
        <div class="m-t-lg">
            <div class="hbox">
                <div class="col">
                    <a ng-click="topics_collapse_state_state[topics] = !topics_collapse_state_state[topics];"><span class="h4 font-bold" ng-init="topics_collapse_state_state[topics] = false">I. GENERAL TOPICS</span> <span><code>*</code></span></a>
                </div>
                <div class="col text-right">
                    <span>
                        <a ng-click="topics_collapse_state_state[topics] = !topics_collapse_state_state[topics];">
                            <i ng-class="{'fa-angle-right' : topics_collapse_state_state[topics], 'fa-angle-down' : !topics_collapse_state_state[topics]}" class="fa fa-fw fa-2x"></i>
                        </a>
                    </span>
                </div>
            </div>

            <div class="line line-double b-b b-t b-primary2"></div>
            <div uib-collapse="topics_collapse_state_state[topics]">
                <p class="font-bold">
                    Select the GENERAL topics for which you are considered an expert by choosing categories from the drop down menu below.
                    Once you select a topic, click the add button to the right and repeat this process until you are finished. Please select up to 5 topics.
                </p>

                <div class="m-b">


                    <div class="hbox">
                        <div class="col" style="width: 400px">
                            <ui-select ng-model="new_topic.selected" theme="bootstrap">
                                <ui-select-match placeholder="Select or type to search ...">{{$select.selected.name}}</ui-select-match>
                                <ui-select-choices repeat="option in topics | filter: $select.search">
                                    <div ng-bind-html="option.name | highlight: $select.search"></div>
                                </ui-select-choices>
                            </ui-select>
                        </div>
                        <div class="col">
                            <div class="m-l">
                                <button ng-click="addTopic()" type="button" class="btn btn-sm btn-default"><i class="fa fa-plus fa-fw"></i> Add Topic</button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- SELECTED TOPICS -->
                <div ng-repeat="topic in faculty_topics track by $index">
                    <div class="line line-dashed b-b b-primary2"></div>
                    <div class="hbox m-b">
                        <div class="col v-middle" style="width: 400px;">
                            <span>{{$index+1}}. {{topic}}</span>
                        </div>
                        <div class="col v-middle">
                            <div class="m-l">
                                <button ng-click="removeTopic(topic)" class="btn btn-xs btn-danger"><i class="fa fa-times text-white"></i> Delete</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="line line-dashed b-b b-primary2"></div>

            </div>
        </div>

        <!-- LECTURES -->
        <div class="m-t-lg">
            <div class="hbox">
                <div class="col">
                    <a ng-click="lectures_collapse_state_state[lectures] = !lectures_collapse_state_state[lectures];"><span class="h4 font-bold" ng-init="lectures_collapse_state_state[lectures] = false">II. LECTURES</span> <span><code>*</code></span></a>
                </div>
                <div class="col text-right">
                    <span>
                        <a ng-click="lectures_collapse_state_state[lectures] = !lectures_collapse_state_state[lectures];">
                            <i ng-class="{'fa-angle-right' : lectures_collapse_state_state[lectures], 'fa-angle-down' : !lectures_collapse_state_state[lectures]}" class="fa fa-fw fa-2x"></i>
                        </a>
                    </span>
                </div>
            </div>

            <div class="line line-double b-b b-t b-primary2"></div>
            <div uib-collapse="lectures_collapse_state_state[lectures]">
                <p class="font-bold">
                    List SPECIFIC lectures you would like to present - be very specific including trial names if appropriate (e.g. "Combined use of eptifibatide and enoxaparin - the INTERACT Trial"). For each lecture, select all the topic categories that apply to the lecture. To select multiple
                    Topic
                    Categories hold the control key down while selecting.You must click the Add button on the right side of the page after each entry. If you do not want to present a lecture check the box marked
                    " I do not have a specific lecture to submit "
                </p>

                <div class="m-b" ng-show="!no_lecture">
                    <div class="hbox">
                        <div class="col">
                            <span class="font-bold m-b-sm">Lecture Name:</span>
                            <input type="text" class="form-control input-sm" ng-model="new_lecture.name"/>
                        </div>
                        <div class="col" style="width: 400px;">
                            <div class="m-l">
                                <span class="font-bold m-b-sm">Topic Categories:</span>
                                <ui-select multiple ng-model="new_lecture.topics" theme="bootstrap">
                                    <ui-select-match placeholder="Click to select topics ... &#x25BC">{{$item.name}}</ui-select-match>
                                    <ui-select-choices repeat="option in topics | filter: $select.search">
                                        <div ng-bind-html="option.name | highlight: $select.search"></div>
                                    </ui-select-choices>
                                </ui-select>
                            </div>
                        </div>
                        <div class="col">
                            <div class="m-l">
                                <button ng-click="addLecture()" type="button" class="m-t-sm btn btn-sm btn-default" style="margin-top: 18px;"><i class="fa fa-plus fa-fw"></i> Add Lecture</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="m-b">
                    <label class="i-checks i-checks-sm">
                        <input type="checkbox" ng-click="setNoLecture()" ng-model="no_lecture">
                        <i></i>
                        I do not have a specific lecture to deliver
                    </label>
                </div>

                <!-- ADDED LECTURES -->
                <div ng-repeat="lecture in faculty_lectures track by $index">
                    <div class="line line-dashed b-b b-primary2"></div>
                    <div class="hbox m-b">
                        <div class="col v-middle">
                            <span>{{$index+1}}. {{lecture.name}}</span>
                        </div>
                        <div class="col v-middle" style="width: 400px;">
                            <div class="m-l">
                                <span ng-repeat="topic in lecture.topics">{{topic}}<span ng-show="!$last">, </span></span>
                            </div>
                        </div>
                        <div class="col v-middle">
                            <div class="m-l">
                                <button ng-click="removeLecture(lecture)" class="btn btn-xs btn-danger"><i class="fa fa-times text-white"></i> Delete</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="line line-dashed b-b b-primary2"></div>

            </div>
        </div>

        <!-- DEBATES -->
        <div class="m-t-lg">
            <div class="hbox">
                <div class="col">
                    <a ng-click="debates_collapse_state_state[debates] = !debates_collapse_state_state[debates];"><span class="h4 font-bold" ng-init="debates_collapse_state_state[debates] = false">III. DEBATES</span> <span><code>*</code></span></a>
                </div>
                <div class="col text-right">
                    <span>
                        <a ng-click="debates_collapse_state_state[debates] = !debates_collapse_state_state[debates];">
                            <i ng-class="{'fa-angle-right' : debates_collapse_state_state[debates], 'fa-angle-down' : !debates_collapse_state_state[debates]}" class="fa fa-fw fa-2x"></i>
                        </a>
                    </span>
                </div>
            </div>

            <div class="line line-double b-b b-t b-primary2"></div>
            <div uib-collapse="debates_collapse_state_state[debates]">
                <p class="font-bold">
                    List SPECIFIC debates you would like to participate in - and check the side you would take (e.g. "Do small CPK-MB elevations after PCI matter? - PRO"). For each debate, select all the topic categories that apply to the debate. To select multiple Topic Categories hold the control
                    key
                    down while selecting.You must click the Add button on the right side of the page after each entry. If you do not want to present a debate check the box marked
                    " I do not have a specific debate to submit "
                </p>

                <div class="m-b" ng-show="!no_debate">
                    <div class="hbox">
                        <div class="col">
                            <span class="font-bold m-b-sm">Debate Name:</span>
                            <input type="text" class="form-control input-sm" ng-model="new_debate.name"/>
                        </div>
                        <div class="col" style="width: 150px">
                            <div class="m-l">
                                <span class="font-bold m-b-sm">Position:</span>

                                <ui-select ng-model="new_debate.position" theme="bootstrap">
                                    <ui-select-match placeholder="Select position ...">{{$select.selected}}</ui-select-match>
                                    <ui-select-choices repeat="option in position_options | filter: $select.search">
                                        <div ng-bind-html="option | highlight: $select.search"></div>
                                    </ui-select-choices>
                                </ui-select>
                            </div>
                        </div>
                        <div class="col" style="width: 400px;">
                            <div class="m-l">
                                <span class="font-bold m-b-sm">Topic Categories:</span>
                                <ui-select multiple ng-model="new_debate.topics" theme="bootstrap">
                                    <ui-select-match placeholder="Click to select topics ... &#x25BC">{{$item.name}}</ui-select-match>
                                    <ui-select-choices repeat="option in topics | filter: $select.search">
                                        <div ng-bind-html="option.name | highlight: $select.search"></div>
                                    </ui-select-choices>
                                </ui-select>
                            </div>
                        </div>
                        <div class="col">
                            <div class="m-l">
                                <button ng-click="addDebate()" type="button" class="m-t-sm btn btn-sm btn-default" style="margin-top: 18px;"><i class="fa fa-plus fa-fw"></i> Add Debate</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="m-b">
                    <label class="i-checks i-checks-sm">
                        <input type="checkbox" ng-click="setNoDebate()" ng-model="no_debate">
                        <i></i>
                        I do not have a specific debate to submit
                    </label>
                </div>

                <!-- ADDED DEBATES -->
                <div ng-repeat="debate in faculty_debates track by $index">
                    <div class="line line-dashed b-b b-primary2"></div>
                    <div class="hbox m-b">
                        <div class="col v-middle">
                            <span>{{$index+1}}. {{debate.name}}</span>
                        </div>
                        <div class="col v-middle" style="width: 150px;">
                            <div class="m-l">
                                <span>{{debate.position}}</span>
                            </div>
                        </div>
                        <div class="col v-middle" style="width: 400px;">
                            <div class="m-l">
                                <span ng-repeat="topic in debate.topics">{{topic}}<span ng-show="!$last">, </span></span>
                            </div>
                        </div>
                        <div class="col v-middle">
                            <div class="m-l">
                                <button ng-click="removeDebate(debate)" class="btn btn-xs btn-danger"><i class="fa fa-times text-white"></i> Delete</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="line line-dashed b-b b-primary2"></div>
            </div>
        </div>


        <!-- LIVE CASE DISCUSSANT -->
        <div class="m-t-lg">
            <div class="hbox">
                <div class="col">
                    <a ng-click="lcd_collapse_state_state[debates] = !lcd_collapse_state_state[debates];"><span class="h4 font-bold" ng-init="lcd_collapse_state_state[debates] = false">IV. LIVE CASE DISCUSSANT</span> <span><code>*</code></span></a>
                </div>
                <div class="col text-right">
                    <span>
                        <a ng-click="lcd_collapse_state_state[debates] = !lcd_collapse_state_state[debates];">
                            <i ng-class="{'fa-angle-right' : lcd_collapse_state_state[debates], 'fa-angle-down' : !lcd_collapse_state_state[debates]}" class="fa fa-fw fa-2x"></i>
                        </a>
                    </span>
                </div>
            </div>

            <div class="line line-double b-b b-t b-primary2"></div>
            <div uib-collapse="lcd_collapse_state_state[debates]">

                <div class="m-b">
                    <div class="row">
                        <div class="col-sm-4">
                            <span class="font-bold">Do you wish to be a live case discussant?  </span>
                        </div>
                        <div class="col-sm-8">
                            <label class="i-checks i-checks-sm">
                                <input type="radio" value="Yes" name="live_case_discussant" ng-model="live_case_discussant">
                                <i></i>
                                Yes
                            </label>

                            <label class="i-checks i-checks-sm">
                                <input type="radio" value="No" name="live_case_discussant" ng-model="live_case_discussant">
                                <i></i>
                                No
                            </label>
                        </div>
                    </div>
                </div>


                <div class="line line-dashed b-b b-primary2"></div>

            </div>
        </div>

        <div class="m-t-xl text-danger" ng-show="error_msg">
            <span class="font-bold">Please fill-out the following required fields:</span>
            <div class="line line-dashed b-b b-danger"></div>
            <span class="font-bold" ng-bind-html="error_msg | unsafe"></span>
            <div class="line line-dashed b-b b-danger"></div>
        </div>

        <div class="m-t-lg text-center">
            <button ng-click="saveTask()" type="button" class="btn btn-success">Save <span ng-show="task.status == '0'">and Continue</span></button>
            <button ng-click="cancelTask()" type="button" class="btn btn-danger m-l-sm ">Cancel</button>
        </div>

    </div>
</div>