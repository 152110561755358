<div data-ng-include="'blocks/conference_logo.html'"></div>

<div class="wrapper-lg">
    <div class="padder-lg">

        <div data-ng-include="'blocks/conference_nav.html'"></div>

        <div class="m-t-lg">

            <div class="m-b-sm font-bold">You must complete each task in order. If you are unable to access a task, please verify you have completed the preceding requirements.</div>

            <div ng-repeat="task in conference.tasks track by $index">
                <div class="row">
                    <div class="col-sm-6">

                        <div ng-switch="task.active">
                            <span ng-switch-when="1" class="font-bold">
                                <a ng-click="redirectToTask(task.slug, task.id, conference.id)">
                                    <span ng-hide="task.slug == 'hotel_form' && !conference.hotel_options">
                                        <i ng-show="task.status == '1'" uib-tooltip="Completed" class="fa fa-fw fa-check-circle text-success"></i>
                                        <i ng-show="task.status == '0'" uib-tooltip="Outstanding" class="fa fa-fw fa-times-circle text-danger"></i>
                                    </span>
                               {{$index + 1}}. {{task.name}}</a>
                            </span>

                            <span ng-switch-default class="text-muted" uib-tooltip="Please finish any earlier tasks to access this task">
                                <span>
                                    <i ng-show="task.status == '1'" uib-tooltip="Completed" class="fa fa-fw fa-check-circle text-success"></i>
                                    <i ng-show="task.status == '0'" uib-tooltip="Outstanding" class="fa fa-fw fa-times-circle text-danger"></i>
                                </span>
                                {{$index + 1}}. {{task.name}}
                            </span>

                        </div>
                    </div>
                    <div class="col-sm-6">
                        <span ng-show="task.updated_at">Last Modified {{task.updated_at | date:'MM/dd/yyyy'}}</span>
                    </div>
                </div>
                <div class="line line-dashed b-b b-primary2"></div>
            </div>
        </div>

        <span class="font-bold">You will be notified as additional forms become available which require your attention.</span>

    </div>
</div>