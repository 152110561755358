<div class="wrapper-xl">
    <div class="row">
        <div class="col-sm-12 padder-lg">
            <h1 class="font-thin h3">Conferences</h1>
            <div class="b-5x b-b b-black m-t-md m-b"></div>

            <div ng-repeat="conference in conferences">
            <h4 class="font-thin">

                <span ng-switch="conference.faculty.availability">
                    <span ng-switch-when="Yes">
                        <a ui-sref="app.conference_tasks({'conference_id' : conference.id})" class="text-info">{{conference.title}}</a>
                    </span>
                    <span ng-switch-when="No">
                        <a ui-sref="app.tasks_invitation_response_initial({'conference_id' : conference.id})" class="text-info">{{conference.title}}</a>
                    </span>
                    <span ng-switch-default>
                        <a ui-sref="app.conference_tasks({'conference_id' : conference.id})" class="text-info">{{conference.title}}</a>
                    </span>
                </span>

            </h4>
                {{conference.datetime_start | date : 'MMM d' : conference.venue.timezone }} - {{conference.datetime_end | date : 'MMM d' : conference.venue.timezone }} | {{conference.venue.name}}
                <div class="line line-dashed line-lg b-b b-black" ng-show="!$last"></div>
            </div>

            <div class="line b-b b-black line-lg"></div>
        </div>
    </div>
</div>
