<div ng-show="conference.banner_url">
    <div>
        <img src="{{conference.banner_url}}" style="width: 100%" />
    </div>
</div>

<h3>{{conference.title}} | {{faculty.last_name}}, {{faculty.first_name}} {{faculty.degree}}</h3>

<div class="text-danger text-danger-print font-bold" ng-show="is_virtual">
    ALL TIME ZONES ARE EASTERN TIME (NEW YORK)
</div>

<!--<div ng-repeat="(key,value) in faculty.assignments | groupBy: 'event.day' track by $index">
    <div class="h4 font-bold">{{key | uppercase}}</div>

    <div class="line line-double line-lg b-b b-t b-primary2"></div>

    <table class="table table-condensed">
    <tr ng-repeat="assignment in value track by $index">

            <td style="width: 125px">
                {{formatMomentDate(assignment.event.datetime_start,'h:mm A')}} - {{formatMomentDate(assignment.event.datetime_end,'h:mm A')}}
            </td>
            <td style="width: 85px">
                <span ng-class="{
                'text-success text-success-print' : assignment.status == 'Accepted',
                'text-danger text-danger-print' : assignment.status == 'Declined',
                'text-warning text-warning-print' : assignment.status == 'Unknown',
                }">{{assignment.status}}</span>
            </td>
            <td>
                <div class="h5 font-thin font-bold">
                    <span>{{assignment.event.title}}</span>
                </div>
                <div class="m-t-sm">{{assignment.moderator_role}} | {{assignment.event.venue}}</div>
                <div class="line line-dashed b-b b-primary2"></div>
           </td>
    </tr>
    </table>

</div>-->

<div ng-repeat="(key,value) in faculty.assignments | groupBy: 'status' track by $index">
    <h4>{{key == 'Unknown' ? 'Unconfirmed' : key}} Assignments</h4>

    <table class="table table-condensed" ng-show="is_virtual">
        <thead>
        <tr>
            <th>Recording Date</th>
            <th>Event</th>
        </tr>
        </thead>
        <tbody>
        <tr ng-repeat="assignment in value | orderBy: 'assignment.event.recording_datetime_start' track by $index">
            <td nowrap>
                {{formatMomentDate(assignment.event.recording_datetime_start, 'MMM D, YYYY')}} <span ng-show="isLiveEvent(assignment.event)" class="label m-r-xs bg-white text-danger text-danger-print">LIVE</span><br>
                {{formatMomentDate(assignment.event.recording_datetime_start, 'h:mm A')}} - {{formatMomentDate(assignment.event.recording_datetime_end, 'h:mm A',  conference.venue.timezone)}}
            </td>
            <td>
                <span class="font-bold">{{assignment.event.title}}</span><br>
                {{assignment.moderator_role}}<br>
                <em class="smaller">A part of:</em> {{assignment.event.breadcrumb}}<br>
                <em class="smaller">Broadcasting: {{formatMomentDate(assignment.event.datetime_start, 'MMM D, YYYY')}},
                    {{formatMomentDate(assignment.event.datetime_start, 'h:mm A')}} - {{formatMomentDate(assignment.event.datetime_end, 'h:mm A',  conference.venue.timezone)}}
                </em>
                <br>
                <em class="smaller" ng-show="assignment.event.duration > 0"><span ng-show="assignment.event.depth == 1">Duration</span><span ng-show="assignment.event.depth > 1">Presentation Length</span>: {{assignment.event.duration}} minutes<br></em>
                <span class="smaller" ng-show="assignment.event.description">{{assignment.event.description}}<br></span>
                <em class="smaller font-bold" ng-show="assignment.event.videoconference_link.url">Videoconference Link: {{assignment.event.videoconference_link.url}}</em>
            </td>
        </tr>
        </tbody>
    </table>

    <table class="table table-bordered" ng-hide="is_virtual">
        <tr class="m-b-md" ng-repeat="assignment in value | orderBy: 'event.datetime_start' track by $index">
            <td>
                <span ng-show="is_virtual && isLiveEvent(assignment.event)" class="text-danger text-danger-print font-bold">LIVE<br></span>
                <strong><span ng-show="is_virtual">Record</span> Date:</strong> {{assignment.event.day}}<br>
                <span ng-hide="is_virtual"><strong>Time:</strong> {{formatMomentDate(assignment.event.datetime_start,'h:mm A')}} - {{formatMomentDate(assignment.event.datetime_end,'h:mm A', conference.venue.timezone)}}<br></span>
                <span ng-show="is_virtual"><strong>Record Time:</strong> {{formatMomentDate(assignment.event.recording_datetime_start,'h:mm A')}} - {{formatMomentDate(assignment.event.recording_datetime_end,'h:mm A', conference.venue.timezone)}}<br></span>
                <strong>Event Title:</strong> {{assignment.event.breadcrumb}}</strong><br>
                <strong>Session / Lecture Title:</strong> {{assignment.event.title}} <br>
                <strong>Role:</strong> {{assignment.moderator_role}}<br>
                <span ng-hide="is_virtual"><strong>Location / Room:</strong> {{assignment.event.venue}}<br></span>
            </td>
        </tr>
    </table>
</div>