<div data-ng-include="'blocks/conference_logo.html'"></div>

<div class="wrapper-lg">
    <div class="padder-lg">

        <div data-ng-include="'blocks/conference_nav.html'"></div>

        <div class="m-t-lg">
            By agreeing to participate as faculty, I attest that:
            <ul>
                <li>I will disclose all relevant financial relationships to CRF and will disclose same to learners verbally and in slides. The information I submit to the CRF Disclosure Declaration form on this website will be printed in the Disclosure Summary prepared for the attendees.</li>
                <li>I will base my contributions on the best scientific evidence available regarding the content I am assigned. My contributions will give a balanced view of therapeutic options and be unbiased.</li>
                <li>My contributions will not promote the products or services of any commercial interest related to this content.</li>
                <li>All scientific research to support a patient care recommendation will conform to generally accepted standards of experimental design, data collection and analysis.</li>
                <li>If I discuss any off-label product use, I will disclose it to participants. I will not use trade names of health care products or services.</li>
                <li>I will not accept any honorarium beyond what has been agreed upon directly with CRF.</li>
                <li>My presentations will be HIPAA compliant (e.g. only used de-identified patient information).</li>
            </ul>
        </div>


        <p>
            I agree to participate as faculty at {{conference.title}}. As faculty, I understand and agree to allow CRF to use my presentation materials for educational purposes during and after the {{conference.title}} conference according to the terms of the release below.
        </p>

        <div class="text-center m-b-lg">

            <div class="btn-group m-t-xs"
                 ng-init="vm.form[form_config.type].field_model[field.name] = (field.value != null) ? field.value : field.default"
            >
                <label ng-class="{ 'btn-default' : availability != 'Yes', 'btn-success active' : availability == 'Yes' }"
                       class="btn btn-xs btn-default"
                       ng-model="availability"
                       uib-btn-radio="'Yes'"
                       uncheckable
                ><i class="fa fa-check text-active"></i> <span>Yes</span></label>

                <label ng-class="{ 'btn-default' : availability != 'No', 'btn-danger active' : availability == 'No' }"
                       class="btn btn-xs btn-default"
                       ng-model="availability"
                       uib-btn-radio="'No'"
                       uncheckable
                ><i class="fa fa-check text-active"></i> <span>No</span></label>

            </div>

            <!--<label class="i-checks i-checks-sm"><input
                    type="radio"
                    name="meeting_availability"
                    ng-model="availability"
                    value="Yes"
                    xxxng-disabled="conference_faculty.availability == 'Yes' || conference_faculty.availability == 'No'"
            ><i></i></label> Yes

            <label class="i-checks i-checks-sm"><input
                    type="radio"
                    name="meeting_availability"
                    ng-model="availability"
                    value="No"
                    xxxng-disabled="conference_faculty.availability == 'Yes' || conference_faculty.availability == 'No'"
            ><i></i></label> No--->

        </div>

        <div class="panel panel-default">
            <div class="panel-body" style="height: 150px; overflow-y: scroll">

                <p>
                    As part of the continuing effort of the Cardiovascular Research Foundation ("CRF") to educate and inform the medical community and the public at-large, the CRF (1) has arranged for the videotaping and audio recording of portions of this conference, including the TCT Scientific
                    Symposia, Main Arena Sessions, Live Case Venues, Concurrent Sessions, Abstract Sessions, and Lunchtime Breakouts (collectively, {{conference.title}}) and (2) intends to include the presentation materials used by the faculty at {{conference.title}} in CD-ROMs or other digital formats
                    that the CRF will prepare and distribute and/or post on the Internet and/or use for other purposes. Accordingly, the CRF requests your permission to record your presentation(s) at {{conference.title}} and to use the recording(s) and your presentation materials during {{conference.title}} and in the future.
                </p>
                <p>
                    Therefore, I hereby authorize and grant permission to the CRF, its successors and assigns, to record my presentation(s) at {{conference.title}} and to use my presentation materials and the recording(s) for such purposes as the CRF in its sole discretion may choose, including, but
                    not limited to, those for which a fee may be charged. I understand, acknowledge and agree that the CRF (i) will have and may exercise all rights of whatever kind or nature in the recording(s) that now or may thereafter be protected by the copyright laws of the United States of
                    America, any other applicable federal or state laws and the applicable laws of all foreign countries; (ii) may publish all or part of the recording(s) and any related handout materials in various print or electronic media and distribute or post them on the Internet; (iii) may
                    edit such recording for stylistic, grammatical or other purposes without affording me the opportunity to review or approve the final version; and (iv) will have the right to include the recording in a compilation publication upon which the CRF may apply for copyright protection;
                    and (v) may, in CRF's sole discretion, decide not to use any or all of my presentation.
                </p>
                <p>
                    I further authorize and grant permission to the CRF to use my name, likeness, photograph and biographical data in connection with the use and/or promotion of the tapes, transcripts or other materials published by the CRF that include all or part of my presentation(s) at {{conference.title}}.
                    It is also understood, acknowledged and agreed that I have not received and will not now or in the future receive a royalty or other monetary compensation from the CRF for the rights I have granted in this Release. It is further understood, acknowledged and agreed that
                    by signing this Release I am not prohibited from using my material in the future in either my personal or professional endeavors and that by providing these various rights, I am not relinquishing or otherwise diminishing any rights that I may have in my presentation materials or
                    the presentation itself.
                </p>
            </div>
        </div>

        <div class="line line-dashed b-b b-primary2"></div>

        <div class="m-t-xl text-danger" ng-show="error_msg">
            <span class="font-bold">Please fill-out the following required fields:</span>
            <div class="line line-dashed b-b b-danger"></div>
            <span class="font-bold" ng-bind-html="error_msg | unsafe"></span>
            <div class="line line-dashed b-b b-danger"></div>
        </div>

        <div class="m-t-lg text-center">
            <button ng-click="saveTask()" type="button" class="btn btn-success">Save and Continue</span></button>
            <button ng-click="cancelTask()" type="button" class="btn btn-danger m-l-sm ">Cancel</button>
        </div>

    </div>
</div>