<h1 class="font-thin h3 m-b-sm">{{conference.title}}</h1>
<div class="m-b-sm">
    <em class="h4">{{formatMomentDate(conference.datetime_start, 'MM/DD/YYYY')}} to {{formatMomentDate(conference.datetime_end, 'MM/DD/YYYY')}}, {{conference.venue.name}}</em>
</div>

<div class="hdr-nav" style="padding-bottom: 15px;" ng-hide="task_initial">
    <div class="hdr-nav-item text-left">
        <a ui-sref="app.conference_tasks({'conference_id' : conference.id})" class="text-md font-thin m-r-xl" ng-class="{'font-bold' : conf_menu == 'tasks'}">Tasks</a>
    </div>
    <!--<div class="hdr-nav-item text-left">
        <a ui-sref="app.conference_notices({'conference_id' : conference.id})" class="text-md font-thin m-r-xl" ng-class="{'font-bold' : conf_menu == 'notices'}">Notices</a>
    </div>--->
    <div class="hdr-nav-item text-left">
        <a ui-sref="app.conference_coordinator({'conference_id' : conference.id})" class="text-md font-thin m-r-xl" ng-class="{'font-bold' : conf_menu == 'contact_coordinator'}">Contact Coordinator</a>
    </div>
    <!--<div class="hdr-nav-item text-left">
        <a ui-sref="app.conference_about({'conference_id' : conference.id})" class="text-md font-thin m-r-xl" ng-class="{'font-bold' : conf_menu == 'about'}">About</a>
    </div>--->
</div>

<div class="b-5x b-b b-black m-t-md"></div>

<!--<div class="m-t m-b-lg" ng-show="conf_menu == 'tasks' && task.name">
    <ul class="breadcrumb m-n bg-frontend-color-2" style="padding: 0px 1px !important;">
        <li><a class="h5 font-thin text-info" ui-sref="app.conference_tasks({'conference_id' : conference.id})">{{conference.title}} Tasks</a></li>
        <li class="active ">Task {{task.ordering}} of ? - {{task.name}}</li>
    </ul>
</div>-->

<div class="m-t m-b-lg" ng-show="conf_menu == 'tasks' && task.name">
    <span class="font-bold h5"><span ng-hide="task_initial">Task {{task.ordering}} -</span> {{task.name}}</span>
</div>

<div class="m-t m-b-lg" ng-show="conf_menu == 'contact_coordinator'">
    <span class="font-bold h5">Contact Coordinator</span>
</div>

<div class="m-t m-b-lg" ng-show="conf_menu == 'notices'">
    <span class="font-bold h5">Notices</span>
</div>

<div class="m-t m-b-lg" ng-show="conf_menu == 'about'">
    <span class="font-bold h5">About</span>
</div>